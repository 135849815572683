import './App.css';
import './assets/css/fontawesome-all.min.css';
import './assets/css/main.css';
import './assets/css/noscript.css';
import './assets/sass/main.scss';
import pic01 from './images/pic01.webp';
import pic02 from './images/pic02.webp';
import pic03 from './images/pic03.webp';

function App() {
  return (
    <div id="page-wrapper" className="App">
      {/* <header className="App-header"> */}
        {/* <img src={logo} className="App-logo" alt="logo" /> */}
        {/* <div id="page-wrapper" > */}

            {/* <header id="header" className="alt">
              <h1><a href="index.html">Spectral</a></h1>
              <nav id="nav">
                <ul>
                  <li className="special">
                    <a href="#menu" className="menuToggle"><span>Menu</span></a>
                    <div id="menu">
                      <ul>
                        <li><a href="index.html">Home</a></li>
                        <li><a href="generic.html">Generic</a></li>
                        <li><a href="elements.html">Elements</a></li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </nav>
            </header> */}

            <section id="banner">
              <div className="inner">
                <h2>FERNANDA ROSSI</h2>
                <p>Fernanda Consultoria de Imagem e Estilo</p>
                {/* <ul className="actions special">
                  <li><a href="#" className="button primary">Activate</a></li>
                </ul> */}
              </div>
              <a href="#one" className="more scrolly">Saiba mais</a>
            </section>

            <section id="one" className="wrapper style1 special">
              <div className="inner">
                <header className="major">
                  <h2> "Nada é tão nosso quanto os nossos sonhos"<br />
                  Friedrich Nietzsche</h2>
                  <p>Venha se descobrir e realizar seus sonhos.</p>
                </header>
                {/* <ul className="icons major">
                  <li><span className="icon fa-gem major style1"><span className="label">Lorem</span></span></li>
                  <li><span className="icon fa-heart major style2"><span className="label">Ipsum</span></span></li>
                  <li><span className="icon solid fa-code major style3"><span className="label">Dolor</span></span></li>
                </ul> */}
              </div>
            </section>

            <section id="two" className="wrapper alt style2">
              <section className="spotlight">
                <div className="image"><img src={pic01} alt="" /></div><div className="content">
                  <h2>Análise de Coloração Pessoal<br />
                  O que é?</h2>
                  <p>A Análise de Coloração Pessoal é um método no qual você irá descobrir através de um teste comparativo com tecidos, quais cores que melhor harmonizam com a sua beleza. É uma ferramenta poderosa, que pode ser estrategicamente usada para valorizar sua beleza natural, contribuindo para uma imagem sofisticada e equilibrada.

É uma ferramenta de autoconhecimento que vai te permitir economizar tempo no momento das compras, ser mais assertiva ao escolher os tons da sua maquiagem, roupas e acessórios e ainda entender qual tonalidade de cabelo mais te favorece.

Vamos conhecer suas cores?</p>
                </div>
              </section>
              <section className="spotlight">
                <div className="image"><img src={pic02} alt="" /></div><div className="content">
                  <h2>Quais cores te favorecem?</h2>
                  <p>A linguagem das cores pode te ajudar mais do que imagina.</p>
                </div>
              </section>
              <section className="spotlight">
                <div className="image"><img src={pic03} alt="" /></div><div className="content">
                  <h2>Qual imagem você quer passar</h2>
                  <p>As cores nada podem fazer se você não controlar qual imagem deseja passar.</p>
                </div>
              </section>
            </section>

            <section id="three" className="wrapper style3 special">
              <div className="inner">
                <header className="major">
                  <h2>Agende uma consulta</h2>
                  <p>Agende uma consulta e tenha um atendimento dedicado e com o profissionalismo que você merece.</p>
                </header>
                {/* <ul className="features">
                  <li className="icon fa-paper-plane">
                    <h3>Arcu accumsan</h3>
                    <p>Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem tincidunt nullam amet leo Aenean ligula consequat consequat.</p>
                  </li>
                  <li className="icon solid fa-laptop">
                    <h3>Ac Augue Eget</h3>
                    <p>Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem tincidunt nullam amet leo Aenean ligula consequat consequat.</p>
                  </li>
                  <li className="icon solid fa-code">
                    <h3>Mus Scelerisque</h3>
                    <p>Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem tincidunt nullam amet leo Aenean ligula consequat consequat.</p>
                  </li>
                  <li className="icon solid fa-headphones-alt">
                    <h3>Mauris Imperdiet</h3>
                    <p>Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem tincidunt nullam amet leo Aenean ligula consequat consequat.</p>
                  </li>
                  <li className="icon fa-heart">
                    <h3>Aenean Primis</h3>
                    <p>Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem tincidunt nullam amet leo Aenean ligula consequat consequat.</p>
                  </li>
                  <li className="icon fa-flag">
                    <h3>Tortor Ut</h3>
                    <p>Augue consectetur sed interdum imperdiet et ipsum. Mauris lorem tincidunt nullam amet leo Aenean ligula consequat consequat.</p>
                  </li>
                </ul> */}
              </div>
            </section>

            <section id="cta" className="wrapper style4">
              <div className="inner">
                <header>
                  <h2>Já sou cliente</h2>
                  <p>Desejo pegar uma cópia do meu dossiê por aqui.</p>
                </header>
                <ul className="actions stacked">
                  <li><a href="#" className="button fit primary">Obter material exclusivo</a></li>
                  <li><a href="#" className="button fit">Aprenda mais (ainda está por vir)</a></li>
                </ul>
              </div>
            </section>

            <footer id="footer">
              <ul className="icons">
                {/* <li><a href="#" className="icon brands fa-twitter"><span className="label">Twitter</span></a></li>
                <li><a href="#" className="icon brands fa-facebook-f"><span className="label">Facebook</span></a></li> */}
                <li><a href="https://www.instagram.com/fernandarossi.estilo/" className="icon brands fa-instagram"><span className="label">Instagram</span></a></li>
                {/* <li><a href="#" className="icon brands fa-dribbble"><span className="label">Dribbble</span></a></li> */}
                <li><a href="nandarossi7@gmail.com" className="icon solid fa-envelope"><span className="label">Email</span></a></li>
              </ul>
              <ul className="copyright">
                <li>&copy; Fernanda Rossi Estilo</li><li>Design: <a href="https://www.linkedin.com/in/samuel-henrique-ferreira-676b3666/">Samuel Henrique Ferreira</a></li>
              </ul>
            </footer>
          {/* </div> */}

        {/* <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a> */}
      {/* </header> */}
    </div>
			
  );
}

export default App;
